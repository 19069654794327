import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'concertina',
	templateUrl: 'concertina.component.html'
})

export class ConcertinaComponent implements OnInit {

	ngOnInit() { }
}