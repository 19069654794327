import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  public isCollapsed = true;
  public _selectedMenuItem: string = '';
  public get selectedMenuItem(): string {
    return this._selectedMenuItem;
  }

  public constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router) {

      this.router.events.subscribe((res) => { 
        this._selectedMenuItem = this.router.url;
    })
  }
}
