import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ClownOetComponent } from './clown-oet/clown-oet.component';
import { ConcertinaComponent } from './concertina/concertina.component';
import { ContactComponent } from './contact/contact.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'clown-oet',
    component: ClownOetComponent
  },
  {
    path: 'concertina',
    component: ConcertinaComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: '*',
    redirectTo: '/home',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
