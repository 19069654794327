import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'clown-oet',
	templateUrl: 'clown-oet.component.html'
})

export class ClownOetComponent implements OnInit {

	ngOnInit() { }
}